<template>
  <div class="recommendation-parent-wrapper" @mousemove="mouseMove()" @scroll="mouseMove()">
    <router-view />
  </div>
</template>

<script>
let timerState;

export default {
  name: 'RecommendationMachine',
  data() {
    return {
      move: false,
      timeout: 60000,
    };
  },
  watch: {
    $route(to, from) {
      if (timerState) {
        clearInterval(timerState);
        timerState = '';
      }
      if (to.path.indexOf('/recommendation/') > -1) {
        this.startCheck();
      }
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    mouseMove() {
      if (this.$route.path != '/recommendation/splash') {
        this.move = true;
        clearInterval(timerState);
        timerState = '';
        this.startCheck();
        // console.log('moving')
      }
    },
    startCheck() {
      if (this.$route.path != '/recommendation/splash') {
        // console.log('start')
        timerState = setInterval(() => {
          this.move = false;
          this.triggerAction();
        }, this.timeout);
      }
    },
    triggerAction() {
      if (!this.move && timerState && this.$route.path != '/recommendation/splash') {
        // console.log('finish')
        clearInterval(timerState);
        timerState = '';
        // this.$router.push('/recommendation/splash')
        if (document.getElementsByClassName('userlog')[0] != undefined) {
          document.getElementsByClassName('userlog')[0].getElementsByTagName('a')[0].click();
        } else {
          this.$router.push('/recommendation/splash');
        }
      }
    },
  },
};
</script>
